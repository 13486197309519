import React, { useState, useEffect } from 'react';
import { Input } from '~/components/Form';
import { ProductKeys } from '~/hooks/useOnboarding';
import { updateAmount } from '~/store/application/applicationSlice';
import { useAppDispatch } from '~/hooks/useAppDispatch';
import { useSelector } from 'react-redux';

import { RootState } from '~/store/types';
import { updateSyncStatus } from '~/store/providers/providersSlice';
import { ProviderSyncStatus } from '~/store/providers/providersSlice';
import { LoaderButton } from '../LoaderButton/LoaderButton';
import useLocalStorage from '~/hooks/useLocalStorage';

// Define the component
const OnboardingTest = ({ }) => {

  const app = useSelector((state: RootState) => state.application);
  const providers = useSelector((state: RootState) => state.providers);

  const localStorage = useLocalStorage('amount');

  const [desiredAmount, setDesiredAmount] = useState(0);

  useEffect(() => {
    setDesiredAmount(localStorage.get());
  }, [])

  // Local state to handle the inputs
  const [localAmounts, setLocalAmounts] = useState(app.amounts);

  const dispatch = useAppDispatch();

  useEffect(() => {
    setLocalAmounts(app.amounts); // Update local state when the prop 'amounts' changes
  }, [app.amounts]);

  // Handle input change
  const handleInputChange = (product: ProductKeys, value: string) => {
    const updatedAmounts = {
      ...localAmounts,
      [product]: Number(value),
    };
    setLocalAmounts(updatedAmounts);
    dispatch(updateAmount(updatedAmounts))
  };

  // Sync changes to parent component
  const syncStart = () => {
    dispatch(updateSyncStatus(ProviderSyncStatus.Syncing))
  };

  const syncStop = () => {
    dispatch(updateSyncStatus(ProviderSyncStatus.Ready))
  };

  return (
    <div data-component="OnboardingTest">
      <h1>Desired amount: <strong>{desiredAmount}</strong></h1>
      {localAmounts && Object.entries(localAmounts).map(([product, amount]) => (
        <div key={product} className="form">
          <label>{product}</label>
          <Input
            type="number"
            value={amount !== 0 ? amount : ''}
            onChange={(e) => handleInputChange(product as 'bl' | 'id' | 'si', e.target.value)}
          />
        </div>
      ))}
      <p>Emulate syncing</p>
      <div className="buttons">
        {providers.syncStatus === ProviderSyncStatus.Syncing && <LoaderButton loading={true}></LoaderButton>}
        {providers.syncStatus !== ProviderSyncStatus.Syncing && (
          <button className="button primary" onClick={syncStart}>Sync Start</button>
        )}
        <button className="button seondary" onClick={syncStop}>Sync Stop</button>
      </div>
    </div>
  );
};

export default OnboardingTest;
