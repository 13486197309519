import React, { FunctionComponent, forwardRef, InputHTMLAttributes, Ref, createRef, MouseEvent } from 'react';
import { HasClassName } from '@monto/react-common-ui';
import { FieldState } from '../Group/Group';

export interface InputProps extends HasClassName, InputHTMLAttributes<HTMLInputElement> {
  icon?: FunctionComponent<HasClassName>
  otherRef?: Ref<HTMLInputElement>
  state?: FieldState
  onIndicatorClick?: (event: MouseEvent<HTMLButtonElement>) => void
  prepend?: string
}

const InputElement: React.FC<InputProps> = ({
  state,
  otherRef,
  className,
  type = 'text',
  icon: Icon,
  onIndicatorClick,
  prepend,
  ...props
}) => {

  const inputRef = otherRef || createRef();

  function showIndicator(Icon: FunctionComponent<HasClassName> | undefined) {

    if (!Icon) return null;

    if (onIndicatorClick) {
      return (
        <div className="button-indicator">
          <button type="button" onClick={onIndicatorClick}>
            <Icon />
          </button>
        </div>
      )
    }

    return (
      <div className="button-indicator">
        <Icon/>
      </div>
    );
  }

  return (
    <div className="input-wrapper">
      {prepend && <span className="prepend">{prepend}</span>}
      <input
        id={props.name}
        ref={inputRef}
        type={type}
        data-state={state}
        {...props}
      />
      {showIndicator(Icon)}
    </div>
  )
};

export const Input = forwardRef<HTMLInputElement, InputProps>(function input(props, ref) {
  return (
    <InputElement otherRef={ref} {...props} />
  )
});
