import { createSlice, createAction } from '@reduxjs/toolkit';
import { ProductKeys } from '~/hooks/useOnboarding';
import { ProductAmountType } from '~/pages/start';

export type AmountsType = {
  [key in ProductKeys]: number
}

export interface ApplicationState {
  currentCompanyId: number | undefined,
  // amounts: ProductAmountType[]
  amounts: AmountsType;
};

const initialState: ApplicationState = {
  currentCompanyId: undefined,
  amounts: {
    'si' : 0,
    'bl' : 0,
    'id' : 0
  }
};

// action to update the sync status
export const setCurrentCompanyId = createAction<number | undefined>('providers/setCurrentCompanyId');
export const updateAmount = createAction<AmountsType>('providers/updateAmount');

const applicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setCurrentCompanyId, (state, action) => {
      state.currentCompanyId = action.payload;
    })
    builder.addCase(updateAmount, (state, action) => {
      state.amounts = { ...state.amounts, ...action.payload };
    })
  }
});

export default applicationSlice.reducer;
